import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import Select from "react-select";
import { BaseUrl, token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";


function EditProduct() {
  const [shortname, setShortName] = useState("");
  const [longName, setLongName] = useState("");
  const [tag, settag] = useState("")
  const [priceRange, setPriceRange] = useState("");
  const [brandApi, setBrandApi] = useState([]);
  const [categoryApi, setCategoryApi] = useState([]);
  const [category, setCategory] = useState("");
  const [Brand, setBrand] = useState("");
  const [desc, setDesc] = useState("");
  const [product, setProduct] = useState("");
  const [productApi, setProductApi] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedRecomend, setSelectRecomend] = useState([]);

  const [recomonded, setRecomonded] = useState([]);
  const [file, setFile] = useState("");
  const [singleProduct, setSingleProduct] = useState([]);
  const [type, setType] = useState("");
  const [value, setValue] = useState("");
  const [rows, setRows] = useState([{ type: "", value: "" }]);
  const [open, setOpen] = useState(false);

  const [hsn, setHsn] = useState("");

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const addRow = (e) => {
    e.preventDefault();
    setRows([...rows, { type: "", value: "" }]);
  };

  const { id } = useParams();

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const getProductbyId = () => {
    axios
      .get(`${BaseUrl}/product_single/${id}`, { headers })
      .then((res) => {
        console.log("alldata", res.data.data)
        setSingleProduct(res.data.data);
        setShortName(res.data.data[0].short_name);
        setLongName(res.data.data[0].long_name);
        setDesc(res.data.data[0].description);
        setBrand(res.data.data[0].brand._id);
        setCategory(res.data.data[0].category._id);
        setPriceRange(res.data.data[0].price_range);
        setHsn(res.data.data[0].hsn);
        setFile(res.data.data[0].image);
        settag(res.data.data[0].tags);



        // // Get recommended products
        // const recommendedProducts = res.data.data[0].recommended_products.map(
        //   (product) => ({
        //     value: product._id,
        //     label: product.short_name,
        //   })
        // );
        // setSelectRecomend(recommendedProducts);

        const moreinfoData = res.data.data[0].more_info.map((product) => ({
          type: product.type,
          value: product.value,
        }));
        setRows(moreinfoData);

        // Get related products
        const relatedProducts = res.data.data[0].related_products.map(
          (product) => ({
            value: product._id,
            label: product.short_name,
          })
        );
        setSelectedOptions(relatedProducts);

        const recommendedproduct = res.data.data[0].recommended_products.map(
          (product) => ({
            value: product._id,
            label: product.short_name,
          })
        );
        setSelectRecomend(recommendedproduct);

        console.log(singleProduct);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategory = () => {
    axios.get(`${BaseUrl}/category`, { headers }).then((res) => {
      setCategoryApi(res.data.data);
    });
    console.log(categoryApi);
    // console.log(category[3].parent_category.name);
  };

  const BrandgetApi = () => {
    axios.get(`${BaseUrl}/brands`, { headers }).then((res) => {
      setBrandApi(res.data.data);
    });
    console.log(brandApi);
    // console.log(category[3].parent_category.name);
  };

  const ProductgetApi = () => {
    axios.get(`${BaseUrl}/product`, { headers }).then((res) => {
      // setProductApi(res.data.data);
      setOptions(res.data.data);
    });
    console.log(productApi);
    // console.log(category[3].parent_category.name);
  };

  useEffect(() => {
    getProductbyId();
    getCategory();
    BrandgetApi();
    ProductgetApi();
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    const formData = new FormData();
    formData.append("short_name", shortname);
    formData.append("long_name", longName);
    formData.append("description", desc);
    formData.append("category", category);
    formData.append("brand", Brand);
    formData.append("price_range", priceRange);
    formData.append("hsn", hsn);
    formData.append("_id", id);
    formData.append("tags", tag);
    formData.append("file", file);
    selectedOptions.forEach((option) => {
      formData.append("related_products[]", option.value);
    });

    // selectedRecomend.forEach((option) => {
    //   formData.append("recommended_products[]", option.value);
    // });

    rows.forEach((item, index) => {
      formData.append(`more_info[${index}][type]`, item.type);
      formData.append(`more_info[${index}][value]`, item.value);
    });

    axios
      .put(`${BaseUrl}/product`, formData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.status == "OK") {
          toast.success("Data updated successfully");
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log("Error updating data:", error);
        toast.error("Data not updated");
      });
  };

  const handleSelectionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleRecomondedChange = (selectedRecomend) => {
    setSelectRecomend(selectedRecomend);
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile); // Store the uploaded file directly
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  return (
    <>

      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  addProduct">
                <h4 className="px-3 py-3">Update Product</h4>
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Short Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="short_name"
                            value={shortname}
                            onChange={(e) => setShortName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Long Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="long_name"
                            value={longName}
                            onChange={(e) => setLongName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Description
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={desc}
                            onChange={handleEditorChange}
                            className="custom-ckeditor"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Category
                          </label>
                          <select
                            className="form-select"
                            name="category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option selected>Select a Category</option>
                            {categoryApi.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Related Product
                          </label>

                          <Select
                            isMulti
                            name="related_products[]"
                            options={options.map((option) => ({
                              value: option._id, // Use option._id as the value
                              label: option.short_name,
                            }))}
                            value={selectedOptions} // Change to selectedOptions
                            onChange={handleSelectionChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Recommended Product
                          </label>

                          <Select
                            isMulti
                            name="recommended_products[]"
                            options={options.map((option) => ({
                              value: option._id, // Use option._id as the value
                              label: option.short_name,
                            }))}
                            value={selectedRecomend} // Change to selectedOptions
                            onChange={handleRecomondedChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Brand
                          </label>

                          <select
                            className="form-select"
                            name="brand"
                            aria-label="Default select example"
                            value={Brand}
                            onChange={(e) => setBrand(e.target.value)}
                          >
                            <option selected>Select a Brand</option>
                            {brandApi.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>


                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Price Range
                          </label>
                          <input
                            type="text"
                            name="price_range"
                            className="form-control"
                            value={priceRange}
                            onChange={(e) => setPriceRange(e.target.value)}
                          />
                        </FormGroup>
                      </Col>


                      {/* <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="file"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </FormGroup>
                      </Col> */}
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="image"
                            // onChange={(e) => setFile(e.target.value)}
                            onChange={handleFileUpload}
                            className="form-control"
                          />
                        </FormGroup>
                        <div className="mt-3">
                          {file && (
                            <p>
                              Uploaded file:{" "}
                              <span className="fw-bold">{file.name}</span>
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Hsn
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="hsn"
                            value={hsn}
                            onChange={(e) => setHsn(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Tag <span className="text-danger">*  <small className="text-muted">Separate each tag with a comma (e.g., Tag1, Tag2, Tag3)</small></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required
                            placeholder="Enter tags separated by commas"
                            value={tag}
                            onChange={(e) => settag(e.target.value)}
                          />

                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <div className="">
                          <div className="w-100 d-flex justify-content-end mb-3">
                            <button
                              onClick={addRow}
                              className="btn bg-primary text-white"
                            >
                              Add Row
                            </button>
                          </div>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Value</th>
                              </tr>
                            </thead>

                            <tbody>
                              {rows.map((row, index) => (
                                <tr key={index}>
                                  <td>
                                    <FormGroup>
                                      <input
                                        type="text"
                                        name={`more_info[${index}].type`}
                                        placeholder="Type"
                                        className="form-control"
                                        value={row.type}
                                        onChange={(e) =>
                                          handleChange(
                                            index,
                                            "type",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup>
                                      <textarea
                                        type="text"
                                        name={`more_info[${index}].value`}
                                        className="form-control"
                                        placeholder="Value"
                                        value={row.value}
                                        onChange={(e) =>
                                          handleChange(
                                            index,
                                            "value",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button className="btn">Update Product</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Loader open={open} />
      </section>
    </>
  );
}

export default adminLayout(EditProduct);
